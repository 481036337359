"use client";

import * as React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn(
      "border-b transition-colors duration-200", // Transition for smooth background change
      "data-[state=open]:bg-gradient-to-t from-[rgba(68,140,213,0.9)] to-[rgba(68,140,213,0.8)] text-white",
      "data-[state=closed]:text-fontColor bg-white rounded-3xl",
      className
    )}
    {...props}
  />
));
AccordionItem.displayName = "AccordionItem";

type AccordionTriggerProps = React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
  iconSrc: string;
  alternateIconSrc: string;
  isOpen: boolean;
  onToggle: () => void;
};

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  AccordionTriggerProps
>(({ className, children, iconSrc, alternateIconSrc, isOpen, onToggle, ...props }, ref) => {
  const handleClick = () => {
    onToggle();
  };
  return (
    <AccordionPrimitive.Header className="flex items-center">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          "flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all hover:no-underline",
          className
        )}
        onClick={handleClick}
        {...props}
      >
        {isOpen ? (
          <span className="h-10 w-10 shrink-0 text-muted-foreground transition-transform duration-200 mr-5 ltr:ml-5">
            <img src={alternateIconSrc} alt="icon" className="w-full" />
          </span>
        ) : (
          <span className="h-10 w-10 shrink-0 text-muted-foreground transition-transform duration-200 mr-5 ltr:ml-5">
            <img src={iconSrc} alt="icon" className="w-full" />
          </span>
        )}
        <span className="flex-1 text-right pr-4 font-bold ltr:text-left">{children}</span>
        <ChevronDownIcon
          className={cn(
            "h-8 w-8 shrink-0 transition-transform duration-200 ml-5 text-dropDownIcon ltr:mr-5",
            isOpen && "rotate-180"
          )}
        />
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  );
});
AccordionTrigger.displayName = "AccordionTrigger";


const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="rounded-b-3xl overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down data-[state=open]:bg-gradient-to-t from-[rgba(25,106,178,0.8)] to-[rgba(68,140,213,0.01)]"
    {...props}
  >
    <div className={cn("pr-16 pb-10 pl-6 text-white font-small ltr:pl-20", className)}>
      {children}
    </div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = "AccordionContent";

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };