export type questionsRespones = {
    value: string;
    question: string;
    answer: string;
};

export const frequentlyAskedQuestions = [
    {
        value: "item-1",
        question: "ما هي منصة مهندس القيم؟",
        answer: "منصة مهندس القيم، هي إحدى مبادرات جمعية مكارم الأخلاق، والتي تهدف إلى تعزيز القيم والأخلاق من خلال برامج تأهيلية، ولقاءات إثرائية متنوعة.",
    },
    {
        value: "item-2",
        question: "ماهي مجالات البرامج التدريبية المقدمة في المنصة؟",
        answer: "جميع المجالات التي تساعد المتدرب في تنمية وتعزيز الجوانب القيمية والأخلاقية.",
    },
    {
        value: "item-3",
        question: "ماذا أستفيد من برامج منصة مهندس القيم؟",
        answer: "صُممت برامج المنصة بناء على دراسات للاحتياج في الجوانب القيمية والأخلاقية.",
    },
    {
        value: "item-4",
        question: "ما هي أنواع البرامج المقدمة من منصة مهندس القيم؟",
        answer: "تقدم منصة مهندس القيم مجموعة من برامج ومواد التعلم الذاتي المستمرة، والتي تمكّن المستفيد من التعلم في أي وقت ومن أي مكان. هذا بالإضافة إلى بعض البرامج الحية المباشرة التي تكون محددة بزمن معين، ولفئات وقطاعات محددة مثل: الملتقيات، وورش العمل، والمعسكرات الافتراضية.",
    },
    {
        value: "item-5",
        question: "هل يمكن للمستفيد الحصول على شهادة إنجاز البرامج التدريبية؟",
        answer: "في حالة الانتهاء من جميع المتطلبات يمكن تحميل شهادة الإنجاز للبرامج التدريبية.",
    },
    {
        value: "item-6",
        question: "هل جميع البرامج التدريبية مجانية؟",
        answer: "نعم جميع البرامج مجانية.",
    },
    {
        value: "item-7",
        question: "هل بإمكان المتدرب الاستفادة من أكثر من برنامج في نفس الوقت؟",
        answer: "نعم؛ يمكن للمتدرب التسجيل في عدة برامج في وقت واحد من برامج ومواد التعلم الذاتي.",
    },
    {
        value: "item-8",
        question: "ماهي متطلبات الالتحاق بالبرامج التدريبية واللقاءات؟",
        answer: "غالب البرامج التدريبية واللقاءات لا تحتاج إلى متطلبات، ولكن إن وجدت ستكون في صفحة البرنامج تحت عنوان متطلبات الدورة أو اللقاء.",
    },
    {
        value: "item-9",
        question: "هل هناك توقيت معين لإتمام البرنامج؟",
        answer: "تقدم مهلة أسبوعان كحد أقصى لبرامج ومواد التعلم الذاتي، وللبرامج الحية المباشرة يتم توضيح توقيت ومتطلبات إتمامها في صفحة التسجيل.",
    },
    {
        value: "item-10",
        question: "كيف يمكن للمتدرب الوصول إلى البرامج التدريبية المسجل بها؟",
        answer: "لمعرفة البرامج التدريبية المسجل بها اضغط على 'ملفي الشخصي' وستظهر جميع البرامج مع مستويات الإنجاز.",
    },
    {
        value: "item-11",
        question:
            "هل يمكن الدخول إلى المنصة من خلال الهاتف المحمول أو الجهاز اللوحي؟",
        answer: "نعم؛ المنصة مصممة لتناسب جميع الأجهزة.",
    },
    {
        value: "item-12",
        question: "ماهي متطلبات الدخول لمنصة مهندس القيم؟",
        answer: "اتصال إنترنت. الدخول عن طريق متصفحات الإنترنت. التسجيل/ تسجيل الدخول في المنصة.",
    },
];
